/**
 * Billboard
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonLink from 'Components/ButtonLink';
import Image from 'Components/Image';
import _ from 'lodash';
import { useStyle } from 'Themes/theme';

import sD from './Billboard.Dansskolanentre.module.scss';
import sK from './Billboard.Kulturama.module.scss';
import sM from './Billboard.Medborgarskolan.module.scss';
import sP from './Billboard.Pahlmans.module.scss';
import sT from './Billboard.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Billboard = ({title, text, link, image, theme}) => {

    const s = useStyle(styles);

    const hasImage = !_.isEmpty(_.get(image, 'src', ''));
    const hasButton = !_.isEmpty(_.get(link, 'text', ''));
    const modifiers = ['Overflow', 'Light', 'Primary'];

    const classes = classNames(
        s['Billboard'],
        {[s['Billboard--Image']]: hasImage},
        {[s[`Billboard--${_.upperFirst(theme)}`]]: !_.isEmpty(theme)},
    );

    return (
        <div className={classes}>
            <div className={s['Billboard__Wrap']}>
                {hasImage &&
                    <div className={s['Billboard__Image']}>
                        <Image
                            {...image}
                            sizes={['100vw']}
                            useCover={true}
                        />
                    </div>
                }
                <div className={s['Billboard__Container']}>
                    {!_.isEmpty(title) &&
                        <h2 className={s['Billboard__Title']}>{title}</h2>
                    }

                    {!_.isEmpty(text) &&
                        <p className={s['Billboard__Text']}>{text}</p>
                    }

                    {hasButton &&
                        <ButtonLink
                            {...link}
                            modifiers={modifiers}
                            componentClass={s['Billboard__Button']}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

Billboard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
    theme: PropTypes.string,
};

Billboard.defaultProps = {
    title: '',
    text: '',
    link: {},
    image: {},
    theme: '',
};

export default Billboard;
