/**
 * Pagination
 */

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Paginate from 'react-paginate';
import IconChevron from '../../public/inline-svg/chevron.svg';
import { useStyle } from 'Themes/theme';

import sD from './Pagination.Dansskolanentre.module.scss';
import sK from './Pagination.Kulturama.module.scss';
import sM from './Pagination.Medborgarskolan.module.scss';
import sP from './Pagination.Pahlmans.module.scss';
import sT from './Pagination.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Pagination = ({
    numPages,
    currentPage,
    labels,
    getPaginationBaseUrl,
    changePageHandler,
    loading,
}) => {
    const s = useStyle(styles);

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        const addRelAttributes = () => {
            const prevBtn = document.querySelector('.js-pagination-prev-btn');
            if (prevBtn) {
                const linkPrev = document.createElement('link');
                linkPrev.rel = 'prev';
                linkPrev.href = prevBtn.href;

                if (prevBtn.href) {
                    document.head.appendChild(linkPrev);
                }

                prevBtn.setAttribute('rel', 'prev');
            }
            const nextBtn = document.querySelector('.js-pagination-next-btn');
            if (nextBtn) {
                const linkNext = document.createElement('link');
                linkNext.rel = 'next';
                linkNext.href = nextBtn.href;

                if (nextBtn.href) {
                    document.head.appendChild(linkNext);
                }
                nextBtn.setAttribute('rel', 'next');
            }
        }
        addRelAttributes();
    }, [isMounted]);

    const previous = <div className={s['Pagination__Chevron']}>
        <span className="sr-only">{labels.previous}</span><IconChevron />
    </div>;

    const next = <div className={s['Pagination__Chevron']}>
        <span className="sr-only">{labels.next}</span><IconChevron />
    </div>;

    const classes = classNames(
        s['Pagination'],
        { [s['Pagination--Loading']]: loading },
    );

    return (
        <div className={classes}>
            {isMounted && (numPages > 1) &&
                <Paginate
                    hrefBuilder={getPaginationBaseUrl}
                    onPageChange={changePageHandler}
                    pageCount={numPages}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    previousLabel={previous}
                    nextLabel={next}
                    forcePage={currentPage - 1}
                    breakClassName={[s['Pagination__Item'] + ' ' + s['Pagination__Item--Dots']]}
                    containerClassName={s['Pagination__List']}
                    pageClassName={s['Pagination__Item']}
                    pageLinkClassName={s['Pagination__Link']}
                    activeClassName={s['Pagination__Item--Current']}
                    previousClassName={[s['Pagination__Item'] + ' ' + s['Pagination__Item--Arrow']]}
                    nextClassName={[s['Pagination__Item'] + ' ' + s['Pagination__Item--Arrow']]}
                    disabledClassName={[s['Pagination__Item'] + ' ' + s['Pagination__Item--Disabled']]}
                    previousLinkClassName={[s['Pagination__Arrow'] + ' ' + s['Pagination__Arrow--Prev'] + ' ' + 'js-pagination-prev-btn']}
                    nextLinkClassName={[s['Pagination__Arrow'] + ' ' + s['Pagination__Arrow--Next'] + ' ' + 'js-pagination-next-btn']}
                />
            }

        </div>
    );
};

Pagination.defaultProps = {
    baseUrl: '',
    numPages: 0,
    currentPage: 1,
    labels: {},
    loading: false,
};

export default Pagination;
