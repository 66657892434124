/**
 * CardCalendar
 */

import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import IconTime from '../../public/inline-svg/time.svg';
import IconLocation from '../../public/inline-svg/location.svg';
import IconAlert from '../../public/inline-svg/new/alert.svg';
import { useStyle } from 'Themes/theme';

import sK from './CardCalendar.Kulturama.module.scss';
import sM from './CardCalendar.Medborgarskolan.module.scss';
import sP from './CardCalendar.Pahlmans.module.scss';

const styles = {
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
};

const CardCalendar = ({
    title,
    link,
    calendardate,
    price,
    meta,
    empty,
    alert,
    modifier,
}) => {
    const s = useStyle(styles);

    let displayMeta = [...meta];
    if(empty) {
        displayMeta = [{type: 'empty'}, {type: 'empty'}];
    }

    const {t} = useTranslation();

    const classes = classNames(
        s['CardCalendar'],
        {[s[`CardCalendar--${modifier}`]]: modifier},
        {[s['CardCalendar--White']]: !modifier},
        {[s['CardCalendar--Empty']]: empty},
        {[s['CardCalendar--Hover']]: !empty},
    );

    return (
        <article className={classes}>
            {!empty &&
                <a className={s['CardCalendar__Link']} href={link.href}>
                    <span
                        className="sr-only"
                        dangerouslySetInnerHTML={{__html: link.text}}
                    />
                </a>
            }
            <div className={s['CardCalendar__Bg']} />
            <div className={s['CardCalendar__Container']}>
                <div className={s['CardCalendar__DateContainer']}>
                    <p className={classNames(s['CardCalendar__Date'], s['CardCalendar__Date--Day'])}>
                        {calendardate.day}
                    </p>
                    <p className={classNames(s['CardCalendar__Date'], s['CardCalendar__Date--Month'])}>
                        {calendardate.month}
                    </p>
                </div>
                <h3 className={s['CardCalendar__Title']}>{title}</h3>

                {!_.isEmpty(price) &&
                    <div className={s['CardCalendar__Price']}>
                        <span className="sr-only">{t('cardCalendar.price')}: </span>
                        {price}
                    </div>
                }

                <div className={s['CardCalendar__Meta']}>
                    {displayMeta.map((item, index) =>
                        <Info
                            {...item}
                            key={index}
                        />
                    )}
                </div>
            </div>

            {!_.isEmpty(alert) &&
                <div className={s['CardCalendar__Alert']}>
                    <div className={s['CardCalendar__AlertWrapper']}>
                        <IconAlert />
                        <p className={s['CardCalendar__AlertMessage']}>{alert}</p>
                    </div>
                </div>
            }
        </article>
    );
};

const Info = ({label, type, text}) => {
    const s = useStyle(styles);

    if(!text) {
        return false;
    }

    let Icon = IconTime;
    if(type === 'location') {
        Icon = IconLocation;
    }

    const classes = classNames(
        s['CardCalendar__Info'],
        s[`CardCalendar__Info--${_.upperFirst(type)}`],
    );

    return (
        <p className={classes}>
            <span className='sr-only'>
                {label}:
            </span>
            <span className={s['CardCalendar__Icon']}>
                <Icon />
            </span>
            {text}
        </p>
    );
};

CardCalendar.defaultProps = {
    title: '',
    calendardate: {
        day: '',
        month: '',
    },
    location: '',
    link: {},
    price: '',
    meta: [],
    alert: '',
    empty: false,
};

export default CardCalendar;
