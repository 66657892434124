/**
 * CalendarList
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import CardCalendar from '../CardCalendar';
import Pagination from '../Pagination';
import Arrow from '../../public/inline-svg/arrow-kulturama.svg';
import Flag from '../../public/inline-svg/flag.svg';
import { useStyle } from 'Themes/theme';

import sK from './CalendarList.Kulturama.module.scss';
import sM from './CalendarList.Medborgarskolan.module.scss';
import sP from './CalendarList.Pahlmans.module.scss';

const styles = {
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
};

const CalendarList = ({
    title,
    calendarTitle,
    items,
    showHeader,
    nrOfItems,
    resultKeyword,
    resultCity,
    paginate,
    loading,
    link,
    modifier,
    cardmodifiers,
    labels,
    getPaginationBaseUrl,
    changePageHandler,
}) => {
    const s = useStyle(styles);

    let displayItems = [...items];
    // Default to four empty items when loading
    if(loading) {
        displayItems = [{empty: true}, {empty: true}, {empty: true}, {empty: true}];
    }

    const displayTitle = _.get(calendarTitle, 'title', title);

    const displayLink = !_.isEmpty(link) && typeof(link) === 'string' ? {
        text: _.get(labels, 'link', ''),
        href: link,
    } : link;

    const count = nrOfItems.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const text = (
        <ResultLabel
            keyword={resultKeyword}
            cities={resultCity}
            label={labels.result}
            count={count}
        />
    );

    const classes = classNames(
        s['CalendarList'],
        {[s[`CalendarList--${modifier}`]]: modifier},
    );

    return (
        <div className={classes}>
            <div className={s['CalendarList__Container']}>
                {showHeader &&
                    <div className={s['CalendarList__Header']}>{text}</div>
                }

                {!_.isEmpty(displayTitle) &&
                    <h2 className={s['CalendarList__Title']}>{displayTitle}</h2>
                }

                {displayItems && (displayItems.length <= 0) &&
                    <div className={s['CalendarList__NoResult']}>
                        <div className={s['CalendarList__NoResultBorder']}>
                        </div>
                        <div className={s['CalendarList__NoResultFlag']}>
                            <Flag />
                        </div>
                        <h3 className={s['CalendarList__NoResultTitle']}>
                            {labels.noResultTitle}
                        </h3>
                        <div className={s['CalendarList__NoResultText']}>
                            {labels.noResultText}
                        </div>
                    </div>
                }

                {displayItems &&
                    <div className={s['CalendarList__Grid']}>
                        {displayItems.map((item, index) =>
                            <div className={s['CalendarList__Item']} key={index}>
                                <CardCalendar
                                    {...item}
                                    modifier={cardmodifiers[index]}
                                />
                            </div>
                        )}
                    </div>
                }

                {!_.isEmpty(paginate) &&
                    <Pagination
                        {...paginate}
                        getPaginationBaseUrl={getPaginationBaseUrl}
                        changePageHandler={changePageHandler}
                        loading={loading}
                    />
                }

                {!_.isEmpty(displayLink) &&
                    <Link {...displayLink} />
                }
            </div>
        </div>
    );
};

CalendarList.defaultProps = {
    title: '',
    items: [],
    showHeader: false,
    nrOfItems: 0,
    loading: false,
    cardmodifiers: [],
    link: '',
    labels: {},
    resultKeyword: '',
    resultCity: '',
    paginate: {},
};

const Link = ({text, href, title, target, rel}) => {
    const s = useStyle(styles);

    return (
        <a
            className={s['CalendarList__Link']}
            href={href}
            title={title}
            target={target}
            rel={rel}
        >
            <Arrow />
            <span className={s['CalendarList__LinkText']}>{text}</span>
        </a>
    );
};

Link.propTypes = {
    text: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
};

Link.defaultProps = {
    text: '',
    href: '',
    title: null,
    target: null,
    rel: null,
};

const ResultLabel = ({keyword, cities, count, label}) => {
    const s = useStyle(styles);

    let string = '';
    if(label) {
        string = label.replace(/{count}/g, count);
        string = string.replace(/{resultKeyword}/g, keyword);
        string = string.replace(/{resultCity}/g, cities);
    }

    const classes = classNames(
        s['CalendarList__ResultText'],
        {[s['CalendarList__ResultText--Keyword']]: keyword !== ''},
        {[s['CalendarList__ResultText--City']]: cities !== ''},
    );
    return (
        <div className={s['CalendarList__ResultLabel']}>
            <p
                className={classes}
                dangerouslySetInnerHTML={{__html: string}}
            />
        </div>
    );
};

export default CalendarList;
