/**
 * LinkList
 */

import React from 'react';

import Arrow from '../../public/inline-svg/arrow.svg';
import classNames from 'classnames';
import { useStyle } from 'Themes/theme';

import sD from './LinkList.Dansskolanentre.module.scss';
import sK from './LinkList.Kulturama.module.scss';
import sM from './LinkList.Medborgarskolan.module.scss';
import sP from './LinkList.Pahlmans.module.scss';
import sT from './LinkList.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};


const LinkList = ({
    title = '',
    text = '',
    items = [],
    modifier = '',
    className = '',
}) => {
    const s = useStyle(styles);

    const classes = classNames(
        s['LinkList'],
        {[s[`LinkList--${modifier}`]]: modifier },
        className,
    );

    return (
        <div className={classes}>
            <div className={s['LinkList__Container']}>
                {title && <h2 className={s['LinkList__Title']}>{title}</h2>}

                {text && <div className={s['LinkList__Text']}>{text}</div>}

                {items.length > 0 && (
                    <div className={s['LinkList__List']}>
                        {items.map((item, index) => (
                            <Item key={index} {...item} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

const Item = ({ title, text, link}) => {
    const s = useStyle(styles);
    
    if (!link) {
        return false;
    }

    return (
        <article className={s['LinkList__Item']}>
            <a
                className={s['LinkList__Link']}
                title={link && link.title}
                href={link && link.href}
            >
                <span
                    className='sr-only'
                    dangerouslySetInnerHTML={{ __html: link.text }}
                />
            </a>
            <div className={s['LinkList__ItemContainer']}>
                <h3 className={s['LinkList__ItemTitle']}>
                    <span className={s['LinkList__ItemLabel']}>
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                        <span className={classNames(s['LinkList__Arrow'], s['LinkList__Arrow--Medborgarskolan'])}><Arrow /></span>
                    </span>
                </h3>
                <div className={s['LinkList__ItemText']}>{text}</div>

                <span className={s['LinkList__Button']} area-hidden="true">
                    <Arrow />
                    <span dangerouslySetInnerHTML={{ __html: link.text }} />
                </span>
            </div>
        </article>
    );
};

export default LinkList;
