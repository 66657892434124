/**
* Grid
*/

import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import BaseContext from 'Layouts/BasePage/BaseContext';
import { useStyle } from 'Themes/theme';

import sD from './Grid.Dansskolanentre.module.scss';
import sK from './Grid.Kulturama.module.scss';
import sM from './Grid.Medborgarskolan.module.scss';
import sP from './Grid.Pahlmans.module.scss';
import sT from './Grid.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Grid = ({
    title = '',
    items = [],
    component = function() {},
    modifier = '',
    itemModifier = '',
    link = {},
    noTopSpacing = false,
    className = '',
}) => {
    const {currentTheme} = useContext(BaseContext);

    const s = useStyle(styles);

    const ItemComponent = component;

    const isFull = modifier === 'Full';
    // Only used by Dansskolan and is default size
    const isHalf = currentTheme === 'Dansskolanentre' && modifier === 'Half';
    // Modifier only used by Dansskolan, otherwise default size for all other themes
    const isThird = currentTheme === 'Dansskolanentre' && (
        modifier === 'Third' || modifier === 'ArchiveList' || modifier === 'CardNews'
    );
    // Used by all except Medborgarskolan
    const isFourth = currentTheme !== 'Medborgarskolan' && (
        modifier === 'Fourcol' || modifier === 'CardArticleSubject'
    );

    const fullSizes = [
        '(min-width: 1380px) 1262px',
        '100vw',
    ];
    const halfSizes = [
        '(min-width: 1380px) 628px',
        '(min-width: 768px) 50vw',
        '100vw',
    ];
    const thirdSizes = [
        '(min-width: 1380px) 414px',
        '(min-width: 1024px) 33vw',
        '(min-width: 768px) 50vw',
        '100vw',
    ];
    const fourthSizes = [
        '(min-width: 1380px) 305px',
        '(min-width: 1024px) 25vw',
        '(min-width: 768px) 33vw',
        '(min-width: 480px) 50vw',
        '100vw',
    ];
    const defaultSizes = currentTheme === 'Dansskolanentre' ? halfSizes : thirdSizes;
    const imageSizes = isFull ? fullSizes : (
        isHalf ? halfSizes : (
            isThird ? thirdSizes : (
                isFourth ? fourthSizes : defaultSizes
            )
        )
    );

    const classes = classNames(
        s['Grid'],
        {[s[`Grid--${modifier}`]]: modifier},
        {[s['Grid--NoTopSpacing']]: noTopSpacing},
        className,
    );

    return (
        <div className={classes}>
            <div className={s['Grid__Container']}>
                {title &&
                    <h2 className={s['Grid__Title']}>{title}</h2>
                }
                <div className={s['Grid__Grid']}>
                    {items.map((item, index) =>
                        <div className={s['Grid__Item']} key={index}>
                            <ItemComponent
                                {...item}
                                modifier={itemModifier}
                                listType="Grid"
                                imageSizes={imageSizes}
                            />
                        </div>
                    )}
                </div>

                {!_.isEmpty(link) &&
                    <div className={s['Grid__Button']}>
                        <Link {...link} />
                    </div>
                }
            </div>
        </div>
    );
};

const Link = ({
    title,
    text,
    href,
    target,
    rel,
}) => {
    const s = useStyle(styles);
    return (
        <a
            className={s['Grid__Link']}
            href={href}
            title={title}
            target={target}
            rel={rel}
        >{text}</a>
    );
};

Link.propTypes = {
    text: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
};

Link.defaultProps = {
    text: '',
    href: '',
    title: null,
    target: null,
    rel: null,
};

export default Grid;
