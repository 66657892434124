/**
 * PageHomeSchool
 */

import React from 'react';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import { basePageWrap } from 'Layouts/BasePage';
import HeroSlider from 'Components/HeroSlider';
import LinkList from 'Components/LinkList';
import Billboard from 'Components/Billboard';
import CardArticle from 'Components/CardArticle';
import Grid from 'Components/Grid';
import CalendarList from 'Components/CalendarList';
import Notification from 'Components/Notification';
import SliderCardNews from 'Components/SliderCardNews';
import SearchInput from 'Components/SearchInput';
import { useStyle } from 'Themes/theme';

import sD from './PageHomeSchool.Dansskolanentre.module.scss';
import sP from './PageHomeSchool.Pahlmans.module.scss';
import sT from './PageHomeSchool.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const PageHomeSchool = ({
    hero,
    notification,
    subSection,
    billboard,
    sliderCardArticle,
    calendarTitle,
    calendarList,
    newsList,
    search,
}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const hasNews = !_.isEmpty(_.get(newsList, 'items', []));
    const hasCalendar = !_.isEmpty(_.get(calendarList, 'items', []));

    const action = _.get(search, 'action', '');

    return (
        <div className={s['PageHomeSchool']}>
            <HeroSlider
                {...hero}
                modifier="PageHome"
            />

            {notification &&
                <Notification {...notification} />
            }

            {!_.isEmpty(action) &&
                <form className={s['PageHomeSchool__Search']} action={action}>
                    <div className={s['PageHomeSchool__SearchContainer']}>
                        <h2 className={s['PageHomeSchool__SearchTitle']}>
                            {t('pageHomeSchool.searchTitle')}
                        </h2>
                        <SearchInput
                            id="global_search_input"
                            type="global"
                            triggerFocus={false}
                        />
                    </div>
                </form>
            }

            {subSection.items && (subSection.items.length > 0) &&
                <LinkList
                    {...subSection}
                    modifier='Columns4'
                />
            }

            {billboard.title &&
                <Billboard
                    title={billboard.title}
                    link={billboard.link}
                    image={billboard.image}
                />
            }

            {hasCalendar &&
                <CalendarList
                    {...calendarList}
                    calendarTitle={calendarTitle}
                    modifier='Home'
                />
            }

            {sliderCardArticle.items &&
                <Grid
                    {...sliderCardArticle}
                    component={CardArticle}
                    modifier='Half'
                />
            }

            {hasNews &&
                <SliderCardNews {...newsList} hasBg={true} />
            }
        </div>
    );
};

PageHomeSchool.defaultProps = {
    subSection: {},
    billboard: {},
    sliderCardArticle: {},
    calendarTitle: '',
    calendarList: {},
    newsList: {},
    hero: {},
    search: {},
};

export default basePageWrap(PageHomeSchool);
